import { Grid, Box, Heading } from "@chakra-ui/react"
import { SocketContext } from "../Contexts/Context"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

const VideoPlayerV2 = () => {
    const { name, callAccepted, myVideo, userVideo, callEnded, stream, call, myVideoStream, userVideoStream, dataContext } = useContext(SocketContext);

    const [loading, set_loading] = useState(true);
    const [classNameUser, set_classNameUser] = useState('col-md-12');
    const [classNameMy, set_classNameMy] = useState('col-md-12');
    const [styleMyVideo, set_styleMyVideo] = useState();

    let { callId } = useParams();

    useEffect( () => { 
        console.log('console video player v2', myVideo)
        setTimeout(() => {
            set_loading(false);
        }, 3000);

    }, [])

    useEffect( () => {
        if ( callAccepted && !callEnded ) {
            set_classNameUser('col-md-6')
            set_classNameMy('col-md-6')
        }

    }, [callAccepted, callEnded])

    const styleVideo = { width: '100%', maxHeight: '80vh', borderRadius: 12, marginBottom: 15,}
   
    return (
        <div className="row">
                {
                    callAccepted && !callEnded && (
                        <div className={classNameUser}>
                            {/* <Heading as="h5">
                                {call.name || dataContext.userToCall.name}
                            </Heading> */}
                            <video playsInline ref={userVideo} autoPlay width="600" style={styleVideo} />
                        </div>
                    )
                }
            <div className={classNameMy}>
            {
                stream && (
                    <>
                        {/* <Heading as="h5">
                            {name || 'Name'}
                        </Heading> */}
                        <video playsInline muted ref={myVideo} autoPlay style={styleVideo} />
                    </>
                )
            }
            </div>
            
        </div>
      
    )


}
    export default VideoPlayerV2
