import React, { useContext } from 'react';
import logo from './logo.svg';
import './App.css';

import { Box, Heading, Container } from '@chakra-ui/react';
import Notifications from './components/Notifications';
import Options from './components/Options';
import VideoPlayer from './components/VideoPlayer';
import { SocketContext } from './Contexts/Context';

import Welcome from './pages/Welcome';
import ErrorPage from './pages/ErrorPage';
import Dashboard from './pages/Dashboard';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import AuthMiddleware from './middlewares/AuthMiddleware';
import CallMode from './pages/CallMode';
import CallModeSerius from './pages/CallModeSerius';
import MyApp from './pages/MyApp';
// import MyAppV3 from './pages/MyAppV3';
import IsVerified from './pages/IsVerified';
const router = createBrowserRouter([
  {
    path: "/",
    element: <Welcome />,
    errorElement: <ErrorPage />,
  },
  {
    element: <AuthMiddleware />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/isVerified/:token/:username",
        element: <IsVerified />,
      },
      {
        path: "/app",
        element: <MyApp />,
      },
      // {
      //   path: "/app3",
      //   element: <MyAppV3 />,
      // },
      {
        path: "/call",
        element: <CallModeSerius />,
      },
      {
        path: "/call/:callId",
        element: <CallModeSerius />,
      },
    ]
  },
]);


function App() {

  return (
    <RouterProvider router={router} />
  )
}

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default App;
