import React, { useContext, useEffect, useState } from 'react'
import { SocketContext } from '../Contexts/Context';
import { useNavigate } from 'react-router-dom';
import { MyNavigate } from '../services/GlobalFunctions';
import { useLocalStorage } from '../hooks/useLocalStorage';

export default function UsersOnlineV2() {
const navigate = useNavigate();
const [user, setUser] = useLocalStorage('user',null);

  const { userOnline, dataContext, setDataContext, callUser } = useContext(SocketContext);
  const [ user_online, set_user_online ] = useState(0);
  var userx = 0;
  const handleCall = (callId, user_name = null) => {
    setDataContext((prevData) => ({
      ...prevData,
      userToCall: {
        call_id: callId,
        name: user_name,
      }
    }))
    // navigate('/call/'+callId,);
    // navigate('/call',);
    callUser(callId,user_name)
  }

  const calculateUser = () => {
    console.log('userx',userx);
    userOnline.map( (v,i) => {
      if ( user.company == v.company ) {
        userx =+ 1;
      }
    })

    set_user_online(userx);
  }

  useEffect( () => {
    calculateUser();
  }, [userOnline])
  return (
    <>
        <h3>User Online {user_online}</h3>
        <div style={{ maxHeight: 250, overflowY: 'scroll', overflow: 'hidden'}}>
            {
                userOnline.map( (v,i) => {
                  if ( user.company == v.company ) {
                    return (
                      <li key={v.conn_id}>{v.user_name} 
                        <b className='ms-2' onClick={() => {
                          handleCall(v.conn_id, v.user_name)
                          }}> 
                            <button type='button'>
                              <i className="fa-solid fa-phone"></i>
                            </button>
                        </b>
                      </li>
                    )
                  } else {
                    return (
                      <li key={v.conn_id}>{v.user_name} 
                        <b className='ms-2' onClick={() => {
                          handleCall(v.conn_id, v.user_name)
                          }}> 
                            <button type='button'>
                              <i className="fa-solid fa-phone"></i>
                            </button>
                        </b>
                      </li>
                    )
                  }
                })
            }
        </div>
    </>
  )
}
