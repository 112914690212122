import { useContext } from "react"
import { Box, Button, Heading, flexbox } from "@chakra-ui/react"
import { SocketContext } from "../Contexts/Context"
    
const NotificationsV2 = () => {
    const { answerCall, call, callAccepted , leaveCall} = useContext(SocketContext);
    // console.log('call.isReceivingCall', call.isReceivingCall)

        
return (
    <>
        {call.isReceivingCall && !callAccepted && (
            <Box  position="fixed"
                top="0"
                left="0"
                width="100%"
                height="100%"
                backgroundColor="rgba(0, 0, 0, 0.5)" // Menentukan warna backdrop dengan transparansi
                display="flex"
                justifyContent="center"
                alignItems="center"
                zIndex="9999"
                gap={12}
            >
                <Box>
                    <Heading as="h3" color={'white'}> {call.name} is calling </Heading>
                    <Box
                        display='flex'
                        gap={15}
                    >
                        <Button variant="outline" colorScheme="red" onClick={leaveCall}>
                            Reject Call
                        </Button>
                        <Button variant="solid" colorScheme="teal" onClick={answerCall} >
                            Answer Call
                        </Button>
                    </Box>

                </Box>
                
            </Box>
        )}
    </>
)
    
return (
    <>
        {call.isReceivingCall && !callAccepted && (
            <Box display="flex" justifyContent="space-around" mb="20">
                <Heading as="h3"> {call.name} is calling </Heading>
                <Button variant="outline" onClick={answerCall} border="1px" borderStyle="solid" borderColor="black">
                    Answer Call
                </Button>
            </Box>
        )}
    </>
)
}
export default NotificationsV2
