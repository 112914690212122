import React, { useContext, useEffect } from 'react';

import { Box, Heading, Container } from '@chakra-ui/react';
import Notifications from '../components/Notifications';
import Options from '../components/Options';
import VideoPlayer from '../components/VideoPlayer';
import VideoPlayerV2 from '../components/VideoPlayerV2';
import OptionsV2 from '../components/OptionsV2';
import NotificationsV2 from '../components/NotificationsV2';
import OptionsV2_NEW from '../components/OptionsV2_NEW';
import UsersOnline from '../components/UsersOnline';
import { SocketContext } from '../Contexts/Context';
import { useLocalStorage } from '../hooks/useLocalStorage';
import LoadingPage from './LoadingPage';
import UsersOnlineV2 from '../components/UsersOnlineV2';

function MyApp() {
  const [user, setUser] = useLocalStorage('user',null);
  const {loading, answerCall, call, callAccepted, masuk , name, setName, me, startDeviceVideo } = useContext(SocketContext);

  useEffect( () => {
    startDeviceVideo()

    if ( process.env.NODE_ENV == 'production' ) {
      setTimeout(() => {
        startDeviceVideo()
      }, 1000);
    }
    

    if (!loading ) {
      setName(user.user_name)
      setTimeout(() => {
        masuk({
          user: user
        })
      }, 1000);
    }
  },[loading])

  if ( loading ) {
    return <LoadingPage />
  }

  return (
    <div style={{ overflow: 'hidden'}}>
        {/* <VideoPlayer /> */}
        <VideoPlayerV2 />
        <UsersOnlineV2 />
        {/* <OptionsV2 /> */}
        <OptionsV2_NEW />
        <NotificationsV2 />
    </div>
  )
}


export default MyApp;
