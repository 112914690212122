import { useState, useContext, useEffect } from "react"
import { Button, Input, FormLabel, Heading, Grid, Box, Container, FormControl } from "@chakra-ui/react"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BiCamera, BiCameraOff, BiClipboard, BiPhoneCall, BiPhoneOff, BiSolidCameraOff } from "react-icons/bi";
import { SocketContext } from "../Contexts/Context";
import { useParams } from "react-router-dom";
import { MyColors } from "../constants/MyColors";
import { MyImages } from "../constants/MyImages";
    
const OptionsV2_NEW = () => {
    const { me, callAccepted, name, setName, callEnded, leaveCall, callUser, myVideo, isCameraOn, toggleCamera, dataContext } = useContext(SocketContext);
    const [idToCall, setIdToCall] = useState('');
    // let { callId } = useParams();
    let callId = dataContext.userToCall.call_id
    useEffect( () => {
        if ( callId ) {
            setIdToCall(callId);
            setTimeout(() => {
                callUser(callId)
            }, 1000);
        }
    }, [])

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="text-center">
                    {
                        isCameraOn ? 
                        <>
                            <Button onClick={toggleCamera} mt="5" mr='5' colorScheme='teal' variant='solid' style={{  height: 64, width: 64, borderRadius: 999, }}>
                                <BiCameraOff size={24}/>
                            </Button>
                        </>
                        :
                        <>
                            <Button onClick={toggleCamera} mt="5" mr='5' colorScheme='teal' variant='solid' style={{  height: 64, width: 64, borderRadius: 999, }}>
                                <BiCamera size={24} />
                            </Button>
                        </>
                    }
                    
                    {
                        callAccepted && !callEnded ? (
                            
                            <Button onClick={leaveCall} mt="5" mr='5'  style={{ backgroundColor: MyColors.red, height: 64, width: 64, borderRadius: 999, }}>
                                <img src={MyImages.close}  style={{ height: 17, width: 17, }}/>
                            </Button>
                        ) : (
                            <Button onClick={() => callUser(idToCall)} mt="5" mr='5' colorScheme='teal' variant='solid' style={{  height: 64, width: 64, borderRadius: 999, }}>
                                <BiPhoneCall size={24} />
                            </Button>
                            
                        )
                    }
                    
                </div>
            </div>
        </div>
    )


return (
    <Container maxW="1200px" m="35px 0" p="0">
        <Box p="10px" border="2px" borderColor="black" borderStyle="solid">
            <FormControl display="flex" flexDirection="column" noValidate aria-autocomplete="none">
                <Grid templateColumns='repeat(2, 1fr)' mt="12">
                    <Grid colSpan={1} p="6">
                        <Heading as="h6"> Account Info </Heading>
                        <FormLabel>Username</FormLabel>
                        <Input marginBottom={5} type='text' value={name} onChange={(e) => setName(e.target.value)} width="100%" />
                        <FormLabel>Your ID</FormLabel>
                        <Input type='text' value={me} readOnly disabled width="100%" />
                        <CopyToClipboard text={me} mt="20">
                            <Button leftIcon={<BiClipboard />} colorScheme='teal' variant='solid'>
                                Copy ID
                            </Button>
                        </CopyToClipboard>
                    </Grid>
                    <Grid colSpan={1} p="6">
                        <Heading as="h6"> Make a Call </Heading>
                        <FormLabel> User id to call </FormLabel>
                        <Input type='text' value={idToCall} onChange={(e) => setIdToCall(e.target.value)} width="100%" />
                        {
                            callAccepted && !callEnded ? (
                                <Button leftIcon={<BiPhoneOff />} onClick={leaveCall} mt="20" colorScheme='teal' variant='info'>
                                    Hang up
                                </Button>
                            ) : (
                                <Button leftIcon={<BiPhoneCall />} onClick={() => callUser(idToCall)} mt="20" colorScheme='teal' variant='solid'>
                                    Call
                                </Button>
                            )
                        }
                    </Grid>
                </Grid>
            </FormControl>
        </Box>
    </Container>
)
}
export default OptionsV2_NEW   
